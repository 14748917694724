import React, { Component } from 'react';
import ReactModal from 'react-modal';
import axios from 'axios';
import Loader from 'react-loader-spinner';

class AddModalInside extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showModalNewCurrent: false,
      showModalFull: false,
      showModalSpinner: false,
      clickdata: '',
      startProp: '',
      userid: '',
      databaseid: '',
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModalNewCurrent = this.handleCloseModalNewCurrent.bind(this);
    this.handleCloseModalFull = this.handleCloseModalFull.bind(this);

    this.analyzeDataset = this.analyzeDataset.bind(this);
    this.analyzeFullDataset = this.analyzeFullDataset.bind(this);
  }

  componentDidMount() {
    ReactModal.setAppElement('body');
    window.analyzeLink = '';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clickdb !== this.props.clickdb || prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        clickdata: this.props.clickdb,
        showModal: this.props.isOpen,
        startProp: this.props.start,
        userid: window.location.pathname.split("/").slice(-2, -1)[0],
        databaseid: window.location.pathname.split("/").slice(-1)[0],
      });
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.props.onResetModal();
  }

  handleCloseModalNewCurrent() {
    this.setState({ showModalNewCurrent: false });
  }

  handleCloseModalFull() {
    this.setState({ showModalFull: false });
  }

  executeCurrentAnalysis(e) {
    const { userid, databaseid } = this.state;
    const analysisType = e.currentTarget.id;

    switch (analysisType) {
      case 'ai':
        window.analyzeLink = '/home/ubuntu/analysis_codes/ai_codes/analyze_last_correct.py';
        break;
      case 'classical':
        window.analyzeLink = '/home/ubuntu/analysis_codes/classical_code/analyze_last_correct.py';
        break;
      case 'hybrid':
        window.analyzeLink = '/home/ubuntu/analysis_codes/classic_peak/analyze_last_correct.py';
        break;
      case 'temp1':
        window.analyzeLink = '/home/ubuntu/analysis_codes/ai_codes/classic_negpeak/analyze_last_correct.py';
        break;
      case 'temp2':
        window.analyzeLink = '/home/ubuntu/analysis_codes/pvc_model/analyze_last_correct.py';
        break;
      case 'inversion':
        window.analyzeLink = '/home/ubuntu/web_analysis/single_invert.py';
        break;
      default:
        console.warn('No matching current analysis type found');
        return;
    }

    const url = `https://web.mybiocalculus.com/html/welcome/analyzeDataset/?userid=${userid}&databaseid=${databaseid}&url=${window.analyzeLink}`;
    axios.get(url)
      .then(() => alert("Analysis completed"))
      .catch(error => console.error("Error:", error));

    this.setState({ showModalNewCurrent: false, showModal: false });
  }

  executeFullAnalysis(e) {
    const { userid } = this.state;
    const analysisType = e.currentTarget.id;

    switch (analysisType) {
      case 'ai':
        window.analyzeLink = '/home/ubuntu/analysis_codes/ai_codes/full_analyze.py';
        break;
      case 'classical':
        window.analyzeLink = '/home/ubuntu/analysis_codes/classical_code/full_analyze.py';
        break;
      case 'hybrid':
        window.analyzeLink = '/home/ubuntu/analysis_codes/classic_peak/full_analyze.py';
        break;
      case 'temp1':
        window.analyzeLink = '/home/ubuntu/analysis_codes/ai_codes/classic_negpeak/full_analyze.py';
        break;
      case 'temp2':
        window.analyzeLink = '/home/ubuntu/analysis_codes/pvc_model/full_analyze.py';
        break;
      case 'inversion':
        window.analyzeLink = '/home/ubuntu/web_analysis/single_invert.py';
        break;
      default:
        console.warn('No matching full analysis type found');
        return;
    }

    const url = `https://web.mybiocalculus.com/html/welcome/analyzeAll/?userid=${userid}&&type=${window.analyzeLink}`;
    axios.get(url)
      .then(() => alert("Analysis completed"))
      .catch(error => console.error("Error:", error));

    this.setState({ showModalFull: false, showModal: false });
  }

  analyzeDataset() {
    this.setState({ showModalNewCurrent: true, showModalFull: false });
  }

  analyzeFullDataset() {
    this.setState({ showModalFull: true, showModalNewCurrent: false });
  }

  render() {
    const customStyles = {
      content: {
        background: 'transparent',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    const customStylesNew = {
      content: {
        top: '38%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        height: '45%',
        width: '35%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    return (
      <React.Fragment>
        <ReactModal isOpen={this.state.showModalSpinner} style={customStyles}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          <span>Analyzing...</span>
        </ReactModal>

        <ReactModal isOpen={this.state.showModal} style={customStyles} contentLabel="Example Modal">
          <div className="blog-pop-box">
            <button className="pull-right closeBtn" onClick={this.handleCloseModal}>
              &#10006;
            </button>
            <div className="fea pull-left" style={{ marginRight: '50px' }}>
              <button style={{ border: 'none' }} onClick={this.analyzeDataset}>
                <img src="../../dist/img/blog-pop1.png" alt="Current Analyze" />
              </button>
              <p style={{ marginLeft: '30%', fontSize: '25px' }}>Current Analyze</p>
            </div>
            <div className="fea pull-right" style={{ marginLeft: '50px' }}>
              <button style={{ border: 'none' }} onClick={this.analyzeFullDataset}>
                <img src="../../dist/img/blog-pop1.png" alt="Full Analyze" />
              </button>
              <p style={{ marginLeft: '30%', fontSize: '25px' }}>Full Analyze</p>
            </div>
          </div>
        </ReactModal>

        <ReactModal isOpen={this.state.showModalNewCurrent} style={customStylesNew} contentLabel="Current Analyze Modal">
          <div className="blog-pop-box">
            <button className="pull-right closeBtn" onClick={this.handleCloseModalNewCurrent}>
              &#10006;
            </button>
            <div className="container col-md-12">
                                    <div className="row" style={{"marginTop":"15%"}}>
                                        <div className="col-md-4">
                                            <button style={{"border":"none"}}><img id="ai" onClick={this.executeCurrentAnalysis.bind(this)}src="../../dist/img/AI.png" alt=""/></button>
                                           
                                        </div>

                                         <div className="col-md-4">
                                             <button style={{"border":"none"}}><img id="classical" onClick={this.executeCurrentAnalysis.bind(this)}src="../../dist/img/CLASSICAL.png" alt=""/></button>
                                            
                                         </div>

                                         <div className="col-md-4">
                                            <button style={{"border":"none"}}><img id="hybrid" onClick={this.executeCurrentAnalysis.bind(this)}src="../../dist/img/HYBRID.png" alt=""/></button>
                                            
                                        </div>
                                         </div>
                                         <div className="row" >
                                        <div className="col-md-4">
                                            <button style={{"border":"none"}} ><img id="temp1" onClick={this.executeCurrentAnalysis.bind(this)}src="../../dist/img/TEMPLATE1.png" alt=""/></button>
                                            
                                        </div>

                                         <div className="col-md-4">
                                             <button style={{"border":"none"}}><img id="temp2" onClick={this.executeCurrentAnalysis.bind(this)}src="../../dist/img/TEMPLATE2.png" alt=""/></button>
                                           
                                         </div>
                                         <div className="col-md-4">
                                             <button style={{"border":"none"}}><img id="inversion" onClick={this.executeCurrentAnalysis.bind(this)}src="../../dist/img/inversion.png" alt=""/></button>
                                           
                                         </div>

                                         </div>
                                     </div>
          </div>
        </ReactModal>

        <ReactModal isOpen={this.state.showModalFull} style={customStylesNew} contentLabel="Full Analyze Modal">
          <div className="blog-pop-box">
            <button className="pull-right closeBtn" onClick={this.handleCloseModalFull}>
              &#10006;
            </button>
            <div className="container col-md-12">
                                    <div className="row" style={{"marginTop":"15%"}}>
                                        <div className="col-md-4">
                                            <button style={{"border":"none"}}><img id="ai" onClick={this.executeFullAnalysis.bind(this)}src="../../dist/img/AI.png" alt=""/></button>
                                           
                                        </div>

                                         <div className="col-md-4">
                                             <button style={{"border":"none"}}><img id="classical" onClick={this.executeFullAnalysis.bind(this)}src="../../dist/img/CLASSICAL.png" alt=""/></button>
                                            
                                         </div>

                                         <div className="col-md-4">
                                            <button style={{"border":"none"}}><img id="hybrid" onClick={this.executeFullAnalysis.bind(this)}src="../../dist/img/HYBRID.png" alt=""/></button>
                                            
                                        </div>
                                         </div>
                                         <div className="row" >
                                        <div className="col-md-4">
                                            <button style={{"border":"none"}} ><img id="temp1" onClick={this.executeFullAnalysis.bind(this)}src="../../dist/img/TEMPLATE1.png" alt=""/></button>
                                            
                                        </div>

                                         <div className="col-md-4">
                                             <button style={{"border":"none"}}><img id="temp2" onClick={this.executeFullAnalysis.bind(this)}src="../../dist/img/TEMPLATE2.png" alt=""/></button>
                                           
                                         </div>
                                         <div className="col-md-4">
                                             <button style={{"border":"none"}}><img id="inversion" onClick={this.executeFullAnalysis.bind(this)}src="../../dist/img/inversion.png" alt=""/></button>
                                           
                                         </div>

                                         </div>
                                     </div>
          </div>
        </ReactModal>
      </React.Fragment>
    );
  }
}

export default AddModalInside;
