import React, { Component } from "react";
import axios from "axios";
import apiUrl from "../../config/config";
import classnames from "classnames";
import $ from "jquery";
import socketIOClient from "socket.io-client";
import { MDBDataTable } from "mdbreact";
import ProgressBtn from "../../components/common/ProgressButton";
import TopNav from "../../components/common/TopNav";
import SideBar from "../../components/common/SideBar";
import AddModalComplete from "../verifier/AddModalComplete";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Select from 'react-select';
import LinearProgress from "@material-ui/core/LinearProgress";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./worker.js";
const socket = socketIOClient("https://web.mybiocalculus.com:5000");

const customStyles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: "10px",
    position: "relative",
  },
  linearProgress: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0.4,
    borderRadius: 4,
  },
};
class VerifierHome extends Component {
  constructor() {
    super();
    this.state = {
      menus: [{ title: "Verifier Home", url: "/verifier-home" },{title:'Patient List',url:'/patient-list'},],
      isModalCompleteOpen: false,
      profileLink: "verifier-profile",
      patients: [],
      nor_minhr: "",
      nor_maxhr: "",
      qtcformula: "",
      qtcvalue_min: "",
      qtcvaluemax: "",
      qrswidth_min: "",
      qrswidth_max: "",
      qrsamp_min: "",
      qrsamp_max: "",
      stelevation: "",
      pwidth_min: "",
      pwidth_max: "",
      pamp_min: "",
      pamp_max: "",
      stdepression: "",
      twidth_min: "",
      twidth_max: "",
      tamp_min: "",
      tamp_max: "",
      printerval_min: "",
      printerval_max: "",
      tachyhr_lower: "",
      tachyhr_upper: "",
      bradyhr_lower: "",
      bradyhr_upper: "",
      pauselength: "",
      graph_gen: "",
      errors: {},
      userid: "",
      smoking: "",
      diabetes: "",
      height: "",
      weight: "",
      hospital: "",
      phone: "",
      patient_name: "",
      email_id: "",
      dob: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      thyroid_function: "",
      cardiac_surgery: "",
      doctors: [],
      verifiers: [],
      selectedVerifierIds: [],
      selectedVerifiers: [],
      doctor_id: "",
      doctor_name: "Choose",
      data: {},
      prog_data: 0,
      loading: false,
      socketNew: socket,
      patientDetails: null,
      ipop: "",
      priority: '',
      reportstatus:'',
      percentageVerified:"",
      verifiertype:'',
      comments:'',

    };
    this.selectItem = this.selectItem.bind(this);
    this.getInfo = this.getInfo.bind(this);
    this.handleModalComplete = this.handleModalComplete.bind(this);

    this.preventDefault = this.preventDefault.bind(this);
  }

  componentDidMount() {
    const verifiertype = localStorage.getItem('verifiertype')
    // console.log('verifierid:',verifiertype)
    this.setState({verifiertype:verifiertype})
    const worker = new Worker();

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    var newstat = this;
    /*socket.on('connect', function(){console.log('connected')});
    socket.on('connect_error', function(err){console.log(err)});
    socket.on('data', function(data){


      newstat.setState({"loading":true});
      
      console.log(newstat.state.loading)
    });
    socket.on('disconnect', function(){console.log('disconnected')});*/
    //   axios
    //        .get(apiUrl+'api/users/alldoctors')
    //        .then(res => {
    //          this.setState({ ['doctors']: res.data })
    //        })
    //        .catch(err => {
    //          this.setState({ ['doctors']: [] })
    //        });

    worker.postMessage({
      name: "alldoctors",
      url: apiUrl + "api/users/alldoctors",
    });
    worker.onmessage = function (event) { };
    worker.addEventListener("message", function (event) {
      newstat.setState({ ["doctors"]: event.data });
      //console.log(newstat.state.doctors);
    });
    axios
      .get(apiUrl + "api/users/getVerifierPatients")
      .then((res) => {
        const sortedData = res.data.sort((a, b) => {
          // Sort by report_status first
           const priorityOrder = { '1': 1, '2': 2, '3': 3}; // Custom order for priority
          const aPriority = priorityOrder[a.priority] || 4;
          const bPriority = priorityOrder[b.priority] || 4;
      if (aPriority!==bPriority){
        return aPriority - bPriority;
      }
         
          const reportOrder = { 0: 1, 2: 2, 4: 3 }; // Order for report_status
          const aReportStatus = reportOrder[a.report_status] || 4;
          const bReportStatus = reportOrder[b.report_status] || 4;
          
          if (aReportStatus !== bReportStatus) {
            return aReportStatus - bReportStatus;
          }
      
          // Then, sort by backup_status (1 should come last)
          const aBackupStatus = a.backup_status === 1 ? 2 : 1;
          const bBackupStatus = b.backup_status === 1 ? 2 : 1;
      
          if (aBackupStatus !== bBackupStatus) {
            return aBackupStatus - bBackupStatus;
          }
      
         
         
        });
      
        // Set the sorted patients data in state
        this.setState({
          patients: sortedData,
        });
     
      

        const data = {
          columns: [
            {
              label: "SL NO",
              field: "slno",
              sort: "asc",
              width: 5,
            },
            {
              label: "PATIENT ID",
              field: "patientId",
              sort: "asc",
              width: 70,
            },
            {
              label: "ADDED DATE",
              field: "added_date",
              sort: "asc",
              width: 70,
            },
            {
              label: "NAME",
              field: "name",
              sort: "asc",
              width: 130,
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: 160,
            },
            {
              label: "REPORT",
              field: "report",
              sort: "disabled",
              width: 150,
            },
          ],
          rows: [],
        };

        var onClick = this.selectItem.bind(this);
        var getInfo = this.getInfo.bind(this);

        var preventDefault = this.preventDefault.bind(this);
        var createdData = "";
        const options = [
          "RPeaks",
          "Classic",
          "Classical",
          "Classic Negative",
          "AI",
          "AIcodes",
        ];
        const defaultOption = options[0];
        sortedData.map(function (patient, i) {
          var url = " ";
          if (patient.LastDatasetId != undefined) {
            if (
              patient.backup_status == 0 ||
              patient.backup_status == undefined
            ) {
              url =
                "/verifier-unanalysed/" +
                patient._id +
                "/" +
                patient.LastDatasetId;

            } else {
              url = " ";
            }
          }

          var anl_btn_txt = "teststart";
          var anl_btn_class = "btn-danger";
          var gen_btn_txt = "Not  Generated";
          var gen_btn_class = "btn-danger";
          var report_link = "#";
          var report_link_target = "";
          var report_status = patient.report_status;
          window.backup_status = patient.backup_status;
          if (patient.report_status == 1) {
            anl_btn_txt = "Analyzing...";
            anl_btn_class = "btn-success";
          }
          if (patient.report_status >= 2) {
            if (
              patient.backup_status == 0 ||
              patient.backup_status == undefined
            ) {
              url =
                "/verifier-chart/" + patient._id + "/" + patient.LastDatasetId;
              // console.log('percentage!!!!!!!!!!!!!!!!!', patient)
            } else {
              url = " ";
            }
            anl_btn_txt = "test";
            anl_btn_class = "btn-success";
          }
          if (patient.report_status >= 4) {
            gen_btn_txt = "View Report";
            gen_btn_class = "btn-success";
            report_link_target = "_blank";
            report_link =
              "https://web.mybiocalculus.com/assets/dist/user_records/" +
              patient._id +
              "/Report" +
              patient._id +
              ".pdf";
          }

          if (
            patient.backup_status == 0 ||
            patient.backup_status == undefined
          ) {
            anl_btn_txt = "TEST3";
          } else if (patient.backup_status == 1) {
            anl_btn_txt = "Retrieve";
          } else if (patient.backup_status == 2) {
            anl_btn_txt = "Retrieving";
          }
          createdData = patient.created_at.split("T");
          data.rows.push({
            slno: i + 1,
            patientId: patient.device_id,
            added_date: createdData[0],
            name: capitalizeFirstLetter(patient.name),
            action: (
              <React.Fragment>
                <div style={{ margin: "auto", width: "100%" }}>
                  <ProgressBtn
                    id={patient._id}
                    bc_stat={patient.backup_status}
                    onSelectModalComplete={this.handleModalComplete}
                    r_stat={patient.report_status}
                    socket={newstat.state.socketNew}
                  />
                    {this.state.verifiertype === "8" && (
  <button
    type="button"
    id={patient._id}
    style={{ float: "left", marginRight: "10px" }}
    onClick={getInfo}
    className="btn btn-warning"
    data-toggle="modal"
    data-target="#editInfoModal"
  >
    Edit
  </button>
)}

                  <button
                    type="button"
                    id={patient._id}
                    onClick={onClick}
                    style={{ float: "left", marginRight: "10px" }}
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#myModalDHome"
                  >
                    Setting
                  </button>

                  
                  <button
    type="button"
    id={patient._id}
    style={{ float: "left", marginRight: "10px" }}
    onClick={getInfo}
    className="btn btn-warning"
    data-toggle="modal"
    data-target="#commentinfomodal"
  >
    Comments
  </button>


                </div>
              </React.Fragment>
            ),
            report: (
              <React.Fragment>
                <a href={report_link} target={report_link_target}>
                  <button type="button" className={"btn " + gen_btn_class}>
                    {gen_btn_txt}
                  </button>
                </a>
                <input type="hidden" className="patientLink" value={url} />
              </React.Fragment>
            ),
          });
        }, this);
        this.setState({ data: data });
      })
      .catch((err) => { });

    $(document).ready(function () {
      $("#mbdTable").on("click", "tr td:nth-child(2)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
          console.log('url')
        }
      });
      $("#mbdTable").on("click", "tr td:nth-child(3)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
          console.log('url:',url)
        }
      });
      $("#mbdTable").on("click", "tr td:nth-child(4)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
          console.log('url')

        }
      });
    });
  }
  handleVerifierChange = (selectedOptions) => {
    this.setState({
      selectedVerifiers: selectedOptions || [] // Update selected verifiers or set empty array if none
    });
    // console.log('selected options',selectedOptions)
    // console.log('selected verifier state',this.state.selectedVerifiers,this.state.selectedVerifierIds)

  };
  submitComment = async (e) => {
    const userid = e.currentTarget.id;
    const comments = this.state.comments // Replace with the actual comment input
  // console.log('comments',comments)
    try {
      const response = await axios.post(`${apiUrl}api/patients/addComment/${userid}`, { comments });
      // console.log(response.data); // Log the response from the server
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };
  
  

  handleModalComplete(id) {
    // console.log("id:", id);
    this.state.userid = id;
    this.setState({ isModalCompleteOpen: true });
  }
  onSubmit = (e) => {
    e.preventDefault();

    const settings = {
      userid: this.state.userid,
      nor_minhr: this.state.nor_minhr,
      nor_maxhr: this.state.nor_maxhr,
      qtcformula: this.state.qtcformula,
      qtcvalue_min: this.state.qtcvalue_min,
      qtcvaluemax: this.state.qtcvaluemax,
      qrswidth_min: this.state.qrswidth_min,
      qrswidth_max: this.state.qrswidth_max,
      qrsamp_min: this.state.qrsamp_min,
      qrsamp_max: this.state.qrsamp_max,
      stelevation: this.state.stelevation,
      pwidth_min: this.state.pwidth_min,
      pwidth_max: this.state.pwidth_max,
      pamp_min: this.state.pamp_min,
      pamp_max: this.state.pamp_max,
      stdepression: this.state.stdepression,
      twidth_min: this.state.twidth_min,
      twidth_max: this.state.twidth_max,
      tamp_min: this.state.tamp_min,
      tamp_max: this.state.tamp_max,
      printerval_min: this.state.printerval_min,
      printerval_max: this.state.printerval_max,
      tachyhr_lower: this.state.tachyhr_lower,
      tachyhr_upper: this.state.tachyhr_upper,
      bradyhr_lower: this.state.bradyhr_lower,
      bradyhr_upper: this.state.bradyhr_upper,
      pauselength: this.state.pauselength,
      graph_gen: this.state.graph_gen,
    };

    // console.log(settings);
    axios
      .post(apiUrl + "api/patients/updateSettings", settings)
      .then((res) => {
        $("#closePopup").click();
        // console.log("onclick");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSubmitInfo = (e) => {
    e.preventDefault();
    this.state.dob = document.getElementsByName("dob")[0].value;

  //   this.state.selectedVerifiers.forEach(verifier => {
  //     console.log("Verifier ID:", verifier.value);
  // });
    

    const info = {
      name: this.state.patient_name,
      patientid:this.state.userid,
      userid: this.state.userid,
      dob: this.state.dob,
      weight: this.state.weight,
      height: this.state.height,
      hospital: this.state.hospital,
      phone: this.state.phone,
      email: this.state.email_id,
      doctorid: this.state.doctor_id,
      ipop: this.state.ipop,
      verifierid: this.state.selectedVerifiers.map(verifier => verifier.value),
      priority:this.state.priority,
      reportstatus:this.state.reportstatus,
      // smoking: this.state.smoking,
      // diabetes: this.state.diabetes,
      af: this.state.af,
      // hyper_tension: this.state.hyper_tension,
      // hyper_lipidemia: this.state.hyper_lipidemia,
      indication: this.state.indication,
      cardiac_surgery: this.state.cardiac_surgery,
      thyroid: this.state.thyroid_function,
    };
    // console.log('info:',info);
    // console.log("info date!!!!!!!!!",this.state);
    axios
      .post(apiUrl + "api/patients/updateinfo_new", info)
      .then((res) => {
        // console.log('info',res.data);

        $(".closePopup").click();
        // window.location.href = "/";
        //   var data = res.data;
        //   console.log("Name",res.data.name);
        //   this.setState({ patient_name: res.data.name });
        //   this.state.patient_name=res.data.name;
        //   console.log("Name",this.state.patient_name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];
    // console.log("onChange");
    // console.log(e.target.name);
    // console.log(e.target.value);
    // console.log(e.target)
    // this.setState({reportstatus:e.target.value})
   
    this.setState({ [e.target.name]: e.target.value });
  };
  onPriorityChange=(e)=>{
    var err=this.state.errors
    delete err[e.target.name]

    // console.log(e.target.value)
    this.setState({priority:e.target.value})
  }
  onReportStatuschange=(e)=>{
    // console.log("report stats change",e.target.value)
    this.setState({reportstatus:e.target.value})
  }

  preventDefault(e) {
    e.preventDefault();
  }

  getInfo(e) {
    this.setState({
      smoking: "",
      diabetes: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      cardiac_surgery: "",
      thyroid_function: "",
    });

    var userid = e.currentTarget.id;
    this.setState({ userid: userid });

    axios
      .get(`${apiUrl}api/patients/getPatinetAdditionalInfo?userid=${userid}`)
      .then((res) => {
        const data = res.data[0];
        
        if (Array.isArray(this.state.patients)) {
          this.state.patients.forEach(patient => {
            if (patient._id === userid) {
              this.setState({ reportstatus: patient.report_status });
              // console.log('report status', patient.report_status);
            }
          });
        }

        // Update state with patient info
        this.setState({
          af: data.af ? "yes" : "no",
          cardiac_surgery: data.cardiac_surgery ? "yes" : "no",
          thyroid_function: data.thyroid,
          diabetes: data.diabetes,
          indication: data.indication,
          height: data.height,
          weight: data.weight,
          hospital: data.hospital,
          phone: data.phone,
          patient_name: data.name,
          email_id: data.email,
          doctor_name: data.doctor_name,
          doctor_id: data.doctorid,
        });

        // Handle DOB formatting
        const dob = new Date(Date.parse(data.dob));
        const formattedDob = `${dob.getMonth() + 1}/${dob.getDate()}/${dob.getFullYear()}`;
        this.setState({ dob: formattedDob });

        // Second API call for verifiers
        return axios.get(`${apiUrl}api/users/allverifiers`);
      })
      .then((res) => {
        const verifiers = res.data;
        const formattedVerifiers = verifiers.map(verifier => ({
          value: verifier._id,
          label: verifier.name,
          patients: verifier.patients, // Assuming patients is an array of user IDs
      }));
      
      const filteredVerifiers = formattedVerifiers.filter(verifier => verifier.patients.includes(userid));
      
      // console.log('All formatted verifiers:', formattedVerifiers);
      // console.log('Filtered verifiers with matching user:', this.state.priority);
      
this.setState({selectedVerifiers:filteredVerifiers})      
      
      
      
        this.setState({ verifiers: formattedVerifiers });
// console.log('formatted verifiers:',verifiers)
        // Third API call for patient search (ipop)
        this.state.patients.forEach(patient => {
          if (patient._id === userid) {
          
            this.setState(
              {comments:patient.comments}
            )
            this.setState({priority:patient.priority})
            this.setState({ ipop: patient.ip_op });
          }
        });

        // console.log("ipop", this.state.patients);

        // Fourth API call for verified percentage
        return axios.get(`${apiUrl}api/users/getVerifiedPercentage/${userid}`);
      })
      .then((res) => {
        const ecgValues = res.data.ecgValues; // Assuming `ecgValues` is an array
        const totalLength = ecgValues.length;
        let verifyCount = 0; // Initialize verify count
    
        ecgValues.forEach((value, index) => {
            if (value.verifyStatus === 1) {
                // console.log(`Link for ECG value at index ${index}:`, value.link); // Assuming there's a 'link' property
                verifyCount++; // Increment the verify count
            }
        });
    
        // console.log('Total verified ECG values:', verifyCount);
        // console.log('Total count:', totalLength);
    
        if (totalLength > 0) {
            // console.log('ECG data is available.');
    
            // Calculate and log the percentage of verified ECG values
            const percentageVerified = (verifyCount / totalLength) * 100;
            // console.log('Percentage of verified ECG values:', percentageVerified.toFixed(2) + '%');
this.setState({percentageVerified:percentageVerified.toFixed(2)})
// console.log('verified percentage:',this.state.percentageVerified)

        } else {
            // console.log('No ECG data found.');
        }
    })
    .catch((error) => {
        console.error('Error fetching ECG data:', error);
    });
    
      
  }


  selectItem(e) {
    var userid = e.currentTarget.id;
    this.setState({ userid: userid });
    this.setState({
      nor_minhr: "",
      nor_maxhr: "",
      qtcformula: "",
      qtcvalue_min: "",
      qtcvaluemax: "",
      qrswidth_min: "",
      qrswidth_max: "",
      qrsamp_min: "",
      qrsamp_max: "",
      stelevation: "",
      pwidth_min: "",
      pwidth_max: "",
      pamp_min: "",
      pamp_max: "",
      stdepression: "",
      twidth_min: "",
      twidth_max: "",
      tamp_min: "",
      tamp_max: "",
      printerval_min: "",
      printerval_max: "",
      tachyhr_lower: "",
      tachyhr_upper: "",
      bradyhr_lower: "",
      bradyhr_upper: "",
      pauselength: "",
      graph_gen: "",
    });
    axios
      .get(apiUrl + "api/patients/settings?userid=" + userid)
      .then((res) => {
        var data = res.data;
        this.setState(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (localStorage.jwtToken == undefined) {
      window.location.href = "/";
    }
    const { errors, patients } = this.state;
    /* const { errors,doctors } = this.state;*/

    let doctorsList =
      this.state.doctors.length > 0 &&
      this.state.doctors.map((item, i) => {
        return (
          <option
            key={i}
            value={item._id}
            selected={this.state.doctor_name == item.name}
          >
            {item.name}
          </option>
        );
      }, this);

    return (
      <div className="wrapper theme-6-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink} />
        <AddModalComplete
          userid={this.state.userid}
          isOpen={this.state.isModalCompleteOpen}
        />
        <SideBar menus={this.state.menus} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper" style={{ marginTop: "30px" }}>
          <div className="container-fluid">
            <div id="myModalDHome" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      General Settings
                    </h4>
                  </div>
                  <form method="post" onSubmit={this.onSubmit}>
                    <div className="modal-body">
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Normal Heart Rate Min
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.nor_minhr,
                              })}
                              name="nor_minhr"
                              required=""
                              id="nor_minhr"
                              onChange={this.onChange}
                              value={this.state.nor_minhr}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Normal Heart Rate Max
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.nor_maxhr,
                              })}
                              name="nor_maxhr"
                              required=""
                              id="nor_maxhr"
                              onChange={this.onChange}
                              value={this.state.nor_maxhr}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Formula
                            </label>
                            <select
                              name="qtcformula"
                              className={classnames("form-control", {
                                errors: errors.qtcformula,
                              })}
                              value={this.state.qtcformula}
                              onChange={this.onChange}
                            >
                              <option value="Bazett">Bazett </option>
                              <option value="Fridericia">Fridericia</option>
                              <option value="Framingham">Framingham</option>
                              <option value="Hodges">Hodges</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Value Min
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qtcvalue_min,
                              })}
                              name="qtcvalue_min"
                              required=""
                              id="qtcvalue_min"
                              onChange={this.onChange}
                              value={this.state.qtcvalue_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Value Max
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qtcvaluemax,
                              })}
                              name="qtcvaluemax"
                              required=""
                              id="qtcvaluemax"
                              onChange={this.onChange}
                              value={this.state.qtcvaluemax}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Width Min(ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrswidth_min,
                              })}
                              name="qrswidth_min"
                              required=""
                              id="qrswidth_min"
                              onChange={this.onChange}
                              value={this.state.qrswidth_min}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Width Max (ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrswidth_max,
                              })}
                              name="qrswidth_max"
                              required=""
                              id="qrswidth_max"
                              onChange={this.onChange}
                              value={this.state.qrswidth_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrsamp_min,
                              })}
                              name="qrsamp_min"
                              required=""
                              id="qrsamp_min"
                              onChange={this.onChange}
                              value={this.state.qrsamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrsamp_max,
                              })}
                              name="qrsamp_max"
                              required=""
                              id="qrsamp_max"
                              onChange={this.onChange}
                              value={this.state.qrsamp_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              ST Elevation (mm)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.stelevation,
                              })}
                              name="stelevation"
                              required=""
                              id="stelevation"
                              onChange={this.onChange}
                              value={this.state.stelevation}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Width Min (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pwidth_min,
                              })}
                              name="pwidth_min"
                              required=""
                              id="pwidth_min"
                              onChange={this.onChange}
                              value={this.state.pwidth_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Width Max (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pwidth_max,
                              })}
                              name="pwidth_max"
                              required=""
                              id="pwidth_max"
                              onChange={this.onChange}
                              value={this.state.pwidth_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pamp_min,
                              })}
                              name="pamp_min"
                              required=""
                              id="pamp_min"
                              onChange={this.onChange}
                              value={this.state.pamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pamp_max,
                              })}
                              name="pamp_max"
                              required=""
                              id="pamp_max"
                              onChange={this.onChange}
                              value={this.state.pamp_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Width Min (ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.twidth_min,
                              })}
                              name="twidth_min"
                              required=""
                              id="twidth_min"
                              onChange={this.onChange}
                              value={this.state.twidth_min}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Width Max (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.twidth_max,
                              })}
                              name="twidth_max"
                              required=""
                              id="twidth_max"
                              onChange={this.onChange}
                              value={this.state.twidth_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tamp_min,
                              })}
                              name="tamp_min"
                              required=""
                              id="tamp_min"
                              onChange={this.onChange}
                              value={this.state.tamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tamp_max,
                              })}
                              name="tamp_max"
                              required=""
                              id="tamp_max"
                              onChange={this.onChange}
                              value={this.state.tamp_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              PR Interval Min{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.printerval_min,
                              })}
                              name="printerval_min"
                              required=""
                              id="printerval_min"
                              onChange={this.onChange}
                              value={this.state.printerval_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              PR Interval Max{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.printerval_max,
                              })}
                              name="printerval_max"
                              required=""
                              id="printerval_max"
                              onChange={this.onChange}
                              value={this.state.printerval_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Tachy Lower Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tachyhr_lower,
                              })}
                              name="tachyhr_lower"
                              required=""
                              id="tachyhr_lower"
                              onChange={this.onChange}
                              value={this.state.tachyhr_lower}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Tachy Upper Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tachyhr_upper,
                              })}
                              name="tachyhr_upper"
                              required=""
                              id="tachyhr_upper"
                              onChange={this.onChange}
                              value={this.state.tachyhr_upper}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Brady Lower Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.bradyhr_lower,
                              })}
                              name="bradyhr_lower"
                              required=""
                              id="bradyhr_lower"
                              onChange={this.onChange}
                              value={this.state.bradyhr_lower}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Brady Upper Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.bradyhr_upper,
                              })}
                              name="bradyhr_upper"
                              required=""
                              id="bradyhr_upper"
                              onChange={this.onChange}
                              value={this.state.bradyhr_upper}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              ST Depression (mm)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.stdepression,
                              })}
                              name="stdepression"
                              required=""
                              id="stdepression"
                              onChange={this.onChange}
                              value={this.state.stdepression}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Pause Length (s){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pauselength,
                              })}
                              name="pauselength"
                              required=""
                              id="pauselength"
                              onChange={this.onChange}
                              value={this.state.pauselength}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Graphs To Be Generated(no:){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.graph_gen,
                              })}
                              name="graph_gen"
                              required=""
                              id="graph_gen"
                              onChange={this.onChange}
                              value={this.state.graph_gen}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-success"
                        style={{ color: "Black" }}
                      >
                        Submit
                      </button>
                      {
                        <button
                          id="closePopup"
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                          style={{ color: "Black" }}
                        >
                          Close
                        </button>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div id="commentinfomodal" className="modal fade" role="dialog" aria-labelledby="commentModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 className="modal-title text-center" id="commentModalLabel">Add comments</h4>
      </div>
     
        <div className="modal-body">
          <textarea
            value={this.state.comments || ''} // Default to empty string if comments is undefined
            style={{ width: "100%", height: "100px", color: 'black' }}
            placeholder="Enter your comments here"
            onChange={(e) => this.setState({ comments: e.target.value })} // Update comments directly in state
          />
        </div>
        <div className="modal-footer">
          <div className="row row-sm-offset">
            <div className="col-md-6 multi-horizontal">
              <button id={this.state.userid} type="submit" className="btn btn-success" style={{ color: "Black" }} onClick={this.submitComment}>
                Submit
              </button>
            </div>
            <div className="col-md-6 multi-horizontal text-left">
              <button type="button" className="btn btn-default closePopup" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      
    </div>
  </div>
</div>

            <div id="editInfoModal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      Edit Patient Information
                    </h4>
                  </div>
                  <form method="post" onSubmit={this.onSubmitInfo}>
                    <div className="modal-body">
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patient_name"
                              onChange={this.onChange}
                              value={this.state.patient_name}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="email_id"
                              onChange={this.onChange}
                              value={this.state.email_id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Doctor Name
                            </label>
                            <select
                              name="doctor_id"
                              className={classnames("form-control", {
                                errors: errors.doctor_id,
                              })}
                              onChange={this.onChange}
                              id="doctor_id"
                            >
                              <option value="Choose" style={{ color: "Black" }}>
                                Choose Doctor
                              </option>
                              {doctorsList}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              DOB
                            </label>
                            <div
                              className="input-group date"
                              id="datetimepicker1"
                            >
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  errors: errors.dob,
                                })}
                                onChange={this.onChange}
                                value={this.state.dob}
                                name="dob"
                              />
                              <span className="input-group-addon">
                                <span className="fa fa-calendar"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          {/* <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Height
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="height"
                              onChange={this.onChange}
                              value={this.state.height}
                            />
                          </div> */}
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          {/* <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Weight
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="weight"
                              onChange={this.onChange}
                              value={this.state.weight}
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Hospital Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="hospital"
                              onChange={this.onChange}
                              value={this.state.hospital}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Phone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              onChange={this.onChange}
                              value={this.state.phone}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                          <label className="form-control-label mbr-fonts-style display-7" style={{ color: "Black" }}>
          Assign Verifier
        </label>
        
        <Select
  isMulti
  name="Assign Verifier"
  options={this.state.verifiers} // Pass the verifier options
  value={this.state.selectedVerifiers} // Set the selected verifiers
  onChange={this.handleVerifierChange} 
  // Update the selected verifiers on change
  className="basic-multi-select"
  classNamePrefix="select"
  placeholder="Select verifiers..."
  styles={{
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'blue' : state.isFocused ? '#e5e5e5' : null,
      color: state.isSelected ? 'white' : 'black',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'blue',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      key: state.data.value, // Use the unique value as the key
      backgroundColor: 'blue',
    }),
    
  }}
/>


        {/* Display selected verifier names below the select element */}
        {this.state.selectedVerifiers.length > 0 && (
          <div style={{ marginTop: '10px' }}>
            <strong>Selected Verifiers:</strong> {this.state.selectedVerifiers.map(verifier => verifier.label).join(', ')}
          </div>
        )}

                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Priority
                            </label>
                            <select
        name="Priority"
        className="form-control"
        value={this.state.priority}
        onChange={this.onPriorityChange}
      >
        <option value="0">NA</option>

        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
      </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Atrial Fibrillation
                            </label>
                            <select
                              name="smoking"
                              className="form-control"
                              value={this.state.af}
                              onChange={this.onChange}
                            >
                              <option value="">Choose </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Report Status
                            </label>
                            <select
                              name="Report Status"
                              className="form-control"
                              value={this.state.reportstatus}
                              onChange={this.onReportStatuschange}
                            >
                              
                              <option value="0">Not Analayzed </option>
                             
                              <option value="2">Analyze Compeleted </option>
                              <option value="4">Report Completed</option>

                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              IP/OP
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patient_name"
                              onChange={this.onChange}
                              value={this.state.ipop}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Indication
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="indication"
                              onChange={this.onChange}
                              value={this.state.indication}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Thyroid Functions
                            </label>
                            <select
                              name="thyroid_function"
                              className="form-control"
                              value={this.state.thyroid_function}
                              onChange={this.onChange}
                            >
                              <option value="Normal">Normal</option>
                              <option value="Hyper">Hyper</option>
                              <option value="Hypo">Hypo</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Cardiac Surgery/Interventions
                            </label>
                            <select
                              name="cardiac_surgery"
                              className="form-control"
                              value={this.state.cardiac_surgery}
                              onChange={this.onChange}
                            >
                              <option value="yes">Yes</option>
                              <option value="no">NO</option>
                            </select>
                            
                            
                           
                          </div>
                         
                        </div>
                        <div className="col-md-6 multi-horizontal">
                        <div className="form-group">
                        <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Verified percentage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="verified_percentage"
                              onChange={this.onChange}
                              value={this.state.percentageVerified}
                              disabled='true'
                            />
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="row row-sm-offset">
                        <div className="col-md-6 multi-horizontal">
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ color: "Black" }}
                          >
                            Submit
                          </button>
                        </div>
                        {
                          <div className="col-md-6 multi-horizontal text-left">
                            <button
                              id=""
                              type="button"
                              className="btn btn-default closePopup"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">Patient List</h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <MDBDataTable
                        id="mbdTable"
                        noBottomColumns
                        data={this.state.data}
                        entries={100} // Set default number of entries to 100

                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifierHome;